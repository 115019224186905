import axios from '@/utils/request'

// 小程序管理-三方列表
export function thirdsList(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/small/thirds`,
    method: 'get',
    params
  })
}
//  小程序管理-编辑三方状态
export function setThirdsStatus(data) {
  return axios({
    url: `/manager/account/small/thirds/${data.thirdId}/status`,
    method: 'put',
    data
  })
}
//  小程序管理-三方详情
export function getThirds(thirdId) {
  return axios({
    url: `/manager/account/small/thirds/${thirdId}`,
    method: 'get'
  })
}

// 小程序管理-三方详情-授权url
export function getThirdsUrl(componentAppId,componentAppSecret) {
  return axios({
    url: `/wxapi/preauthurl?componentAppId=`+ componentAppId + `&componentAppSecret=`+componentAppSecret,
    method: 'get'
  })
}

//  小程序管理-编辑三方
export function setThirds(data) {
  return axios({
    url: `/manager/account/small/thirds/${data.thirdId}`,
    method: 'post',
    data
  })
}
// 小程序列表
export function appletList(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/small/apps`,
    method: 'get',
    params
  })
}
// 小程序列表-编辑小程序
export function setApps(data) {
  return axios({
    url: `/manager/account/small/apps/${data.smallId}`,
    method: 'post',
    data
  })
}
// 小程序列表--小程序详情
export function getApps(smallId) {
  return axios({
    url: `/manager/account/small/apps/${smallId}`,
    method: 'get'
  })
}
// 小程序列表--授权二维码
export function getAppsAuthqrcode(smallId) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/authqrcode`,
    method: 'post',
    responseType: 'arraybuffer'
  })
}


// 小程序列表-小程序状态变更 
export function setAppsStatus(smallId, data) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/status`,
    method: 'post',
    data
  })
}


// 小程序列表-小程序状态变更-审核
export function parsingExcelAddData(data, smallId,) {
  console.log('请求上传接口', data)
  return axios({
    url: `/manager/account/small/apps/${smallId}/upload`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      transformRequest: [data => data]

      // 'smallId': smallId      //放到formData里后端拿不到，所以放到请求头里了
    },
    data
  })
}
//小程序列表-同步隐私设置
export function setSyncPrivacy(smallId) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/searchstatus`,
    method: 'get',
  })
}

// 小程序列表-小程序名称检测
export function isNameCheck(smallId, data) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/name/check`,
    method: 'post',
    data
  })
}
// 小程序管理-查询原始ID
export function getOriginid(smallId) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/originid`,
    method: 'get'
  })
}
// 小程序管理-重新认证
export function getReverify(data) {
  return axios({
    url: `/manager/account/small/apps/${data.smallId}/reverify`,
    method: 'post',
    data
  })
}

// 获取体验二维码 
export function getQr(params) {
  return axios({
    url: `/manager/account/small/getQrCode`,
    method: 'get',
    responseType: "blob",
    params
  })
}

// 添加体验者  
export function setUser(params) {
  return axios({
    url: `/manager/account/small/setUser`,
    method: 'get',
    params
  })
}

// 设置规则 
export function setRules(params) {
  return axios({
    url: `/manager/account/small/qrcodejumpadd`,
    method: 'get',
    params
  })
}

// 检验/变更小程序
export function testOrChange(smallId,data) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/change`,
    method: 'post',
    data
  })
}

// 查询次数 
export function getInquiryCount(params) {
  return axios({
    url: `/manager/account/small/queryquota`,
    method: 'get',
    params
  })
}

// 请求类目数据 
export function getCategoryList(params) {
  return axios({
    url: `/manager/account/small/categories`,
    method: 'get',
    params
  })
}

// 小程序管理-终端小程序配置列表
export function alloctreminals(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/alloctreminals`,
    method: 'get',
    params
  })
}

//小程序管理-终端小程序配置-权限配置
export function settreminalconfigStatus(id,statusType) {
  return axios({
    url: `/manager/account/treminalConfig/${id}?statusType=`+statusType,
    method: 'get',
  })
}

//小程序管理-终端小程序配置-其他配置信息-获取
export function getOtherconfigInfo() {
  return axios({
    url: `/manager/account/allocmerchants/picture/getWxapp`,
    method: 'get',
  })
}

//小程序管理-终端小程序配置-其他配置信息-编辑
export function otherconfigPicture(data) {
  return axios({
    url: `/manager/account/allocmerchants/picture/wxapp`,
    method: 'post',
    data
  })
}

// 取消授权小程序
export function delAuthorize(smallId,params) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/delAuthorize`,
    method: 'get',
    params
  })
}
// 获取半屏小程序授权列表
export function ownListEmbedded(smallId,params) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/getOwnList`,
    method: 'get',
    params
  })
}
// 设置授权方式
export function setAuthorize(smallId,params) {
  return axios({
    url: `/manager/account/small/apps/${smallId}/setAuthorize`,
    method: 'get',
    params
  })
}

//小程序管理-小程序列表-新增-商户创建小程序--获取授权码-跳转授权页
export function appThirdId(thirdId, params) {
return axios({
    url: `/wxapi/preauthurl/${thirdId}`,
    method: "get",
    params,
  });
}

// code校验(授权完成后调回来请求的接口)
export function authCodeCb(params) {
  return axios({
    url: `/manager/account/small/message/redirect/callback`, 
    method: "get",
    params,
  });
}