<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>小程序名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="小程序名称/编号/主体"
          ></el-input>
        </div>
        <div class="item">
          <i>所属第三方</i>
          <el-select
            v-model="query.thirdId"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(v, i) in thirdDrop"
              :key="i"
              :label="v.thirdName"
              :value="v.thirdId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <i class="jianju">认证状态</i>
          <el-select
            v-model="query.verifyStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已认证" :value="true"></el-option>
            <el-option label="未认证" :value="false"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item">
          <i>部署状态</i>
          <el-select
            v-model="query.deployStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已部署" :value="true"></el-option>
            <el-option label="未部署" :value="false"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>创建方式</i>
          <el-select
            v-model="query.appType"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option value="QUICK_CREATE" label="快速创建"></el-option>
            <el-option value="REUSE" label="复用创建"></el-option>
            <el-option value="MERCHANT" label="商户创建"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button @click="getLists" type="primary" class="searchBt"
            >查询</el-button
          >
          <el-button
            v-auth="'ACCOUNT:WXAPP:APPS/ADD'"
            @click="addclickHander"
            class="outputBt"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>小程序名称</p>
          </td>
          <td>
            <p>小程序APPID</p>
          </td>
          <td>
            <p>小程序主体</p>
          </td>
          <td>
            <p>所属第三方</p>
          </td>
          <td style="width: 80px">
            <p>认证状态</p>
          </td>
          <td style="width: 80px">
            <p>部署状态</p>
          </td>
          <td style="width: 80px">
            <p>创建方式</p>
          </td>
          <td style="width: 60px">
            <p>版本号</p>
          </td>
          <td style="width: 60px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.createTime ? v.createTime : "" }}</p>
          </td>
          <td>
            <p>{{ v.appName ? v.appName : "" }}</p>
          </td>
          <td>
            <p>{{ v.appId ? v.appId : "" }}</p>
          </td>
          <td>
            <p>{{ v.principalName ? v.principalName : "" }}</p>
          </td>
          <td>
            <p>{{ v.thirdName ? v.thirdName : "" }}</p>
          </td>
          <td style="width: 80px">
            <p>
              {{
                v.verifyStatus === null? "未认证": v.verifyStatus? "已认证": "未认证"}}</p>
          </td>
          <td style="width: 80px">
            <p>
              {{
                v.deployStatus === null
                  ? ""
                  : v.deployStatus
                  ? "已部署"
                  : "未部署"
              }}
            </p>
          </td>
          <td style="width: 80px">
            <p>{{ appType[v.appType] }}</p>
            
          </td>
          <td style="width: 60px">
            <p>{{ v.appVersion ? v.appVersion : "" }}</p>
          </td>
          <td style="width: 60px">
            <p>
              <a
                v-auth="'ACCOUNT:WXAPP:APPS/DETAIL'"
                href="javascript:;"
                class="lianjie"
                @click="showTab(v)"
                >详情</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
    <el-dialog title="新增小程序" :visible.sync="centerDialogVisible" width="490px">
      <ul>
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title">请选择录入方式</div>
          <el-radio v-model="radio" label="QUICK_CREATE">快速创建小程序</el-radio>
          <el-radio v-model="radio" label="REUSE">复用公众号创建小程序</el-radio>
          <el-radio v-model="radio" label="MERCHANT">商户创建小程序</el-radio>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="addBt2" @click="addMiniProgram(radio)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { thirdsDrop } from "@/api/common.js";
import { appletList } from "@/api/user/applet.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        enabledStatus: "",
        keyword: "",
        thirdId: "",
        verifyStatus: "",
        deployStatus: "",
        appType: "", 
      },
      totalNum: 0,
      tabData: [],
      thirdDrop: [],
      radio: "QUICK_CREATE",
      centerDialogVisible: false,
      appType: {
        QUICK_CREATE: "快速创建",
        REUSE: "复用创建",
        MERCHANT: "商户创建",
      },
    };
  },
  computed: {
    ...mapState({
      appletQueryDetail: "user_appletQuery",
    }),
  },
  created() {
    if (this.appletQueryDetail) {
      this.query = this.appletQueryDetail.query;
    }
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    ...mapMutations({
      setAppletQuery: "user_setAppletQuery",
    }),
    // 新增创建小程序
    addMiniProgram(radio){
      this.$router.push({ name: 'applet_list_add', query: { status: radio } })
    },
    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      appletList(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.apps;
          // console.log("表格数据为",this.tabData)
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 详情
    showTab(v) {
      this.$router.push({
        name: "applet_list_detail",
        query: {
          id: v.smallId,
          pageType: "edit",
        },
      });
    },
    //新增按钮
    addclickHander() {
      this.centerDialogVisible = true;
    },
    // 获取下拉
    getDrop() {
      thirdsDrop().then((res) => {
        if (res) {
          this.thirdDrop = res.resultData;
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setAppletQuery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item i {
  width: 74px;
}
.con_from .lineP .itemTime {
  width: 48.283%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 74px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 74px - 24.67px - 10px) / 2);
}
.addBt {
  float: right;
  width: 90px;
  height: 36px;
  border-color: #d7dae2;
  background-color: #fff;
  margin-left: 20px;
  padding: 0;
  color: #606266;
}

.addBt:hover {
  background-color: #fff;
  color: #48b8b6;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.el-radio {
  height: 36px;
  line-height: 36px;
}
.addBt2 {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}
</style>
